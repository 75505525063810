import { api } from '../Api'

export class ExtratoMensalNFService {
  getExtratoNFs(mes, ano) {
    return api.get('/extrato/' + mes + '/' + ano + '?idPrestadorLogado=' + localStorage.getItem('idPrestadorLogado')).then(res => res.data)
  }

  getItensNota(row) {
    return api.get('/lote/itens/' + row.idLote +  '?idPrestadorLogado=' + localStorage.getItem('idPrestadorLogado')).then(res => res.data.body)
  }

  getPesquisaServico(cnpj, dataInicio, dataFim, status, certificado, assistencia) {
    return api.get('/extrato-servico/pesquisar/' + dataInicio + '/' + dataFim + '/' + status + '/' + certificado + '/' + assistencia + '/').then(res => res.data)
  }

  postGravarProtocolo (cnpj, dadosBody) {
    return api.post('/extrato-servico/criar-lote/' + cnpj + '?login=' + localStorage.getItem('login') +
      '&idPrestadorLogado=' + localStorage.getItem('idPrestadorLogado'), dadosBody ).then(res => res.data)
  }

  getDetalharMercadorias(row) {
    return api.get('/notaFiscal/detalhar-mercadorias/' + row.certificado ).then(res => res.data)
  }

}
