import React, { Component } from 'react'
import classNames from 'classnames'
import 'primereact/resources/themes/nova-light/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import { AppTopbar } from './AppTopbar'
import { AppMenu } from './AppMenu'
import { AppProfile } from './AppProfile'
import { JUVO, VERSAO } from './constants/Version'

import { ExtratoMensalNF } from './pages/NFPrestador/ExtratoMensalNF'
import { ExtratoDeServicos } from './pages/NFPrestador/ExtratoDeServicos'
import { Cronograma } from './pages/Cadastros/Cronograma'
import { Contrato } from './pages/Cadastros/Contrato'

import { Login } from './pages/Login/Login'
import { Home } from './pages/Home'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import './layout/layout.scss'
import './App.scss'

class App extends Component {
  constructor () {
    super()
    this.state = {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      height: 0
    }

    this.onWrapperClick = this.onWrapperClick.bind(this)
    this.onToggleMenu = this.onToggleMenu.bind(this)
    this.onSidebarClick = this.onSidebarClick.bind(this)
    this.onMenuItemClick = this.onMenuItemClick.bind(this)
    this.isPaginadeLogin = this.isPaginadeLogin.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.createMenu()
  }

  updateWindowDimensions () {
    this.setState({ width: window.innerWidth, height: window.innerHeight - 85 })
  }

  onWrapperClick (event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      })
    }
    this.menuClick = false
  }

  onToggleMenu (event) {
    this.menuClick = true

    if (this.isDesktop()) {
      if (this.state.layoutMode === 'overlay') {
        this.setState({ overlayMenuActive: !this.state.overlayMenuActive })
      } else if (this.state.layoutMode === 'static') {
        this.setState({ staticMenuInactive: !this.state.staticMenuInactive })
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive
      this.setState({ mobileMenuActive: !mobileMenuActive })
    }

    event.preventDefault()
  }

  onSidebarClick (event) {
    this.menuClick = true
  }

  onMenuItemClick (event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      })
    }
  }

  createMenu () {
    this.menu = [
      { label: 'Página Principal', icon: 'pi pi-fw pi-home', command: () => { window.location = '/home' } },
      {
        label: 'Nota Fiscal Prestador', icon: 'pi pi-money-bill',
        items: [
          // PRIMEIRA PUBLICAÇÃO // { label: 'Cadastro de Nota', icon: 'pi pi-list', command: () => { window.location = '/nota' } },
          { label: 'Extrato Mensal de NF', icon: 'pi pi-fw pi-chart-bar', command: () => { window.location = '/extratoMensal' } },
          { label: 'Extrato de Serviços', icon: 'pi pi-fw pi-chart-bar', command: () => { window.location = '/extratoServico' } },
          { label: 'Cronograma', icon: 'pi pi-fw pi-calendar', command: () => { window.location = '/cronograma' } },
          { label: 'Contrato', icon: 'pi pi-fw pi-calendar', command: () => { window.location = '/contrato' } }
        ]
      }
      //  {
      //   label: 'Nota Fiscal Prestador', icon: 'pi pi-fw pi-cog',
      //   items: [
      //   {label: 'Static Menu', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutMode: 'static'}) },
      //   {label: 'Overlay Menu', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutMode: 'overlay'}) }
      //   ]
      // },
      // {
      //   label: 'Menu Colors', icon: 'pi pi-fw pi-align-left',
      //   items: [
      //   {label: 'Dark', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutColorMode: 'dark'}) },
      //   {label: 'Light', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutColorMode: 'light'}) }
      //   ]
      // },
      // {
      //   label: 'Components', icon: 'pi pi-fw pi-globe', badge: '9',
      //   items: [
      // 		{label: 'Sample Page', icon: 'pi pi-fw pi-th-large', to: '/sample'},
      // 		{label: 'Forms', icon: 'pi pi-fw pi-file', to: '/forms'},
      // 		{label: 'Data', icon: 'pi pi-fw pi-table', to: '/data'},
      // 		{label: 'Panels', icon: 'pi pi-fw pi-list', to: '/panels'},
      // 		{label: 'Overlays', icon: 'pi pi-fw pi-clone', to: '/overlays'},
      // 		{label: 'Menus', icon: 'pi pi-fw pi-plus', to: '/menus'},
      // 		{label: 'Messages', icon: 'pi pi-fw pi-spinner',to: '/messages'},
      // 		{label: 'Charts', icon: 'pi pi-fw pi-chart-bar', to: '/charts'},
      // 		{label: 'Misc', icon: 'pi pi-fw pi-upload', to: '/misc'}
      //   ]
      // },
      // {
      //   label: 'Template Pages', icon: 'pi pi-fw pi-file',
      //   items: [
      //   {label: 'Empty Page', icon: 'pi pi-fw pi-circle-off', to: '/empty'}
      //   ]
      // },
      // {
      //   label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
      //   items: [
      //   {
      //     label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
      //     items: [
      //     {
      //       label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
      //       items: [
      //       {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark'},
      //       {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark'},
      //       {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark'},
      //       ]
      //     },
      //     {
      //       label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
      //       items: [
      //       {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark'},
      //       {label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark'}
      //       ]
      //     },
      //     ]
      //   },
      //   {
      //     label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
      //     items: [
      //     {
      //       label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
      //       items: [
      //       {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark'},
      //       {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark'},
      //       {label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark'},
      //       ]
      //     },
      //     {
      //       label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
      //       items: [
      //       {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark'},
      //       {label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark'}
      //       ]
      //     }
      //     ]
      //   }
      //  ]
      // },

    ]
  }

  addClass (element, className) {
    if (element.classList)
      {element.classList.add(className)}
    else
      {element.className += ' ' + className}
  }

  removeClass (element, className) {
    if (element.classList)
      { element.classList.remove(className) }
    else
      {element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')}
  }

  isDesktop () {
    return window.innerWidth > 1024
  }

  isPaginadeLogin () {
    const history = createBrowserHistory()
    return history.location.pathname === '/'
  }

  isPaginaPrincipal () {
    const history = createBrowserHistory()
    return history.location.pathname === '/home'
  }

  componentDidMount () {
    this.updateWindowDimensions()
  }

  componentDidUpdate () {
    if (this.state.mobileMenuActive)
      {this.addClass(document.body, 'body-overflow-hidden');}
    else
      {this.removeClass(document.body, 'body-overflow-hidden');}
  }

  render () {
  // const logo = this.state.layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg': 'assets/layout/images/logo.svg';

    const wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive
    })

    const sidebarClassName = classNames('layout-sidebar', {
      'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
      'layout-sidebar-light': this.state.layoutColorMode === 'light'
    })

  return (
    <div className={wrapperClass} onClick={this.onWrapperClick}>
      {this.isPaginadeLogin() ? <div />
      : <AppTopbar onToggleMenu={this.onToggleMenu} />}
        {this.isPaginadeLogin() ? <div />
      : <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>

            <AppProfile />
            <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
            <div className='rodape'>{JUVO}<div>{VERSAO}</div></div>
          </div>}

        <div className='layout-main'>

          {this.isPaginadeLogin()
      ? <BrowserRouter>
              <Switch>
                <Route path='/' exact component={Login} />
              </Switch>
            </BrowserRouter>
      

      : <div className='p-grid'>
              <div className='p-col-12'>

                {this.isPaginaPrincipal()
          ? <BrowserRouter>
                    <Switch>
                      <Route path='/home' component={Home} />
                    </Switch>
                  </BrowserRouter>

        :        <div className='card card-w-title card-w-master' style={{ height: this.state.height }}>
                    <BrowserRouter>
                      <Switch>
                        {/* 
                        <Route path='/nota/' exact component={NotaFiscal} />
                        <Route path='/nota/:cnpj/:lote' component={NotaFiscal} />*/}
                        <Route path='/extratoServico' component={ExtratoDeServicos} /> 
                        <Route path='/extratoMensal' component={ExtratoMensalNF} />
                        <Route path='/cronograma' component={Cronograma} />
                        <Route path='/contrato' component={Contrato} />
                      </Switch>
                    </BrowserRouter>
                  </div>}

              </div>
            </div>}

        </div>

        <div className='layout-mask' />
      </div>
    )
  }
}

export default App
