import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { CronogramaService } from '../../service/cadastros/CronogramaService'
import { config } from '../../constants/Environment'
import { TituloPagina } from '../TituloPagina'

export class Cronograma extends Component {
  constructor () {
    super()
    this.cronograma = new CronogramaService()
 
    this.state = {
      layoutMode: 'static',
      api_url: config.url.API_URL,
      msgSuccess: { severity: 'success', summary: 'Sucesso', detail: 'Solicitação realizada com sucesso' }
    }
  }

  componentDidMount () {
    this.onLogin()
    this.onBuscarCronograma()
  }

  onBuscarCronograma () {
    this.cronograma.getCronograma()
      .then(servicosPendentes1 => this.setState({ servicosPendentes: servicosPendentes1 }))
      .catch((err) => {
        if (err.response != null) {
          this.growl.show({ severity: 'warn', summary: 'Atenção', detail: err.response.data.body })
        } else {
          alert('Problemas ao se conectar com o servidor')
        }
      })
  }

  onLogin () {
    const token = localStorage.getItem('user_token')
    const idPrestadorLogado = localStorage.getItem('idPrestadorLogado')

    if (token === null || token.length < 10) {
      window.location = '/'
    }

    if (idPrestadorLogado === null) {
      window.location = '/'
    }
  }

  render () {
    return (
      <div className='p-grid'>
        <TituloPagina titulo='Cronograma' descricao='Relação anual dos Cronogramas Cadastrados' />
        <div className='p-col-12'>
          <DataTable value={this.state.servicosPendentes} paginator={false} responsive>
            <Column field='dataRecepcaoInicial' header='Data Inicial' style={{ textAlign: 'center', width: '15%' }} />
            <Column field='dataRecepcaoFinal' header='Data Final' style={{ textAlign: 'center', width: '15%' }} />
            <Column field='dataPagamento' header='Data Pagamento' style={{ textAlign: 'center', width: '15%' }} />
            <Column field='tipoServico' header='Tipo do Serviço' style={{ textAlign: 'left' }} />
            <Column field='tipoNotaFiscal' header='Tipo da Nota Fiscal' style={{ textAlign: 'left', width: '30%' }} />
          </DataTable>
        </div>
      </div>
    )
  }
}