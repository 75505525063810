import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class AppTopbar extends Component {

    static defaultProps = {
        onToggleMenu: null
    }

    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired
    }

    onClickDeleteRowGrid = () => {
        localStorage.setItem('user_token', null);
        localStorage.setItem('user_name', "");
        localStorage.setItem('idPrestadorLogado', null);
        //window.location = '#/';
        window.location = '/'

    }

    render() {
        const nome = localStorage.getItem('user_name');

        return (
            <div className="layout-topbar clearfix">
                <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
                    <span className="pi pi-bars"/>
                </button>
                <span className="tituloBarraTop">PORTAL MONTADOR</span>
                <div className="layout-topbar-icons">
                  {/*  <span className="layout-topbar-search">
                        <InputText type="text" placeholder="Search" />
                        <span className="layout-topbar-search-icon pi pi-search"/>
                    </span>
                     <button className="p-link">
                        <span className="layout-topbar-item-text">Events</span>
                        <span className="layout-topbar-icon pi pi-calendar"/>
                        <span className="layout-topbar-badge">5</span>
                    </button>
                */}
                    <button className="p-link">
                        <span className="username_top">{nome}</span>
                    </button> 
                    <button className="p-link">
                        <span title="Sair"  onClick={this.onClickDeleteRowGrid}  className="layout-topbar-icon pi pi-sign-out"/>
                    </button> 
                </div>
            </div>
        );
    }
}