import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import axios from 'axios';
import { Growl } from 'primereact/growl';
import { config } from '../../constants/EnvironmentLogin'
import { bearer } from '../../constants/Bearer'

import './Login.css';

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
            errorFieldPass: false,
            errorFieldLogin: false,
            isValid: false,
            GRUPO_MONTADORES: 4162907
        }
    }

    setlogin = (event) => {
        this.setState({ login: event.target.value });
    }

    setPassword = (event) => {
        if (event.target.value.length > 4) {
            this.setState({
                password: event.target.value,
                isValid: true
            })
        } else {
            this.setState({ password: event.target.value });
        }
    }
	
	passwordKeyPressed = (event) => {		
		if(event.key === 'Enter') {
			this.onSubmit();
		}
    }

    onSubmit = () => {

        this.setState({ errorFieldPass: false });
        this.setState({ errorFieldLogin: false });

        let loginValidado = false;
        const isValid = this.validarCampos();
        console.log(process.env.NODE_ENV);

        if (isValid) {
            const API_URL = config.url.API_URL;

            let data = JSON.stringify({
                password: this.state.password,
                login: this.state.login,
                canal: "3"
            })


            axios.post(API_URL, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer.url.BEARER
                }}).then((response) => {
                    
                    
                    for (let index = 0; index < response.data.profiles.length; index++) {
                        const element = response.data.profiles[index];
                        if(element.id === this.state.GRUPO_MONTADORES){
                            localStorage.setItem('user_token', response.data.token);
                            localStorage.setItem('user_name', response.data.name);
                            localStorage.setItem('idPrestadorLogado', response.data.idPrestador);
                            localStorage.setItem('login', response.data.login);
                            loginValidado = true;
                        }
                    }
    
                }).then(() => {
                    loginValidado ?  window.location = '/home': 
                    this.growl.show({ severity: 'error', summary: 'Grupo do Login Inválido', detail: 'O perfil não está asssociado ao grupo Montadores' });
                    
                })
                .catch((error) => {
                    console.log(error.response);

                    this.growl.show({ severity: 'error', summary: 'Problemas ao se conectar', detail: error.response.data.mensagem });

                })
        } else {

            if (!this.state.password) { this.setState({ errorFieldPass: true }); }
            if (!this.state.login) { this.setState({ errorFieldLogin: true }); }

            this.growl.show({ severity: 'warn', summary: 'Campos Obrigatórios', detail: 'Preencha todos os campos.' });
        }

    }

    validarCampos = () => {
        let isValid = true;

        if (this.state.password === null || this.state.login === null ||
            this.state.password === "" || this.state.login === "") {

            isValid = false;
        }

        return isValid;
    }

    enviarMsgErro = () => {
        this.growl.show(this.state.msgSuccess);
    }


    render() {
        return (
            <div id="rootLogin">
                <div className="login-body login-body2" >
                    <div className="login-panel"></div>
                    <div className="login-content">
                        <img src={require('../../assets/images/mms.png')} alt="Tempo Assist2" />
                        <p>Bem-vindo! Informe seu usuário e senha para entrar no sistema.</p>
                        <div className="login-input-wrapper">
                            <InputText id="user" placeholder="Usuário"
                                className={"login-input-wrapper " + (this.state.errorFieldLogin ? "p-error" : " ")}
                                value={this.state.login} 
                                onChange={(e) => this.setlogin(e)} />
                        </div>


                        <div className="login-input-wrapper">
                            <Password
                                className={"login-input-wrapper " + (this.state.errorFieldPass ? "p-error" : " ")}
                                feedback="false" placeholder="Senha" 
                                value={this.state.password} onChange={(e) => this.setPassword(e)} onKeyDown={(e) => this.passwordKeyPressed(e)} />
                        </div><br /><br />
                        <Button onClick={() => this.onSubmit()} label="Entrar" />

                    </div>
                </div>
                <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />
            </div>
        )
    }
}