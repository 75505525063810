import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { TipoEmissaoService } from '../../service/notaFiscal/TipoEmissaoService';
import { ExtratoMensalNFService } from '../../service/notaFiscal/ExtratoMensalNFService';
import { Growl } from 'primereact/growl';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { config } from '../../constants/Environment'
import { traducaoCalendar } from '../../constants/TraducaoCalendarBR'
import { TituloPagina } from '../TituloPagina';
import { Editor } from "primereact/editor";
import {InputMask} from 'primereact/inputmask';
import './nfPrestador.css';

export class ExtratoDeServicos extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            api_url: config.url.API_URL,
            servicosPendentes: [],
            apenasValidos: [],
            cnpj: null,
            dadosEmpresa: [],
            itensSelecionados: [],
            numeroNF: null,
            valorNF: null,
            dataInicio: null,
            dataFim: null,
            status: 'true',
            valorTotal: 0,
            certificado:'',
            assistencia:'',
            dataInicioFmt: null,
            dataFimFmt: null,
            visible: false,
            popUpMercadoria: false,
            visible3: false,
            txtNumeroProtocolo: null,
            cnpjRequired:false,
            dataInicioRequired : false,
            dataFimRequired : false,

            msgSuccess: { severity: 'success', summary: 'Sucesso', detail: 'Solicitação realizada com sucesso' }
        };
        this.export = this.export.bind(this);
        this.tipoEmissaoService = new TipoEmissaoService();
        this.onLimparCampos = this.onLimparCampos.bind(this);
        this.onPesquisaServicos = this.onPesquisaServicos.bind(this);
        this.onNovoLote = this.onNovoLote.bind(this);
        this.onFecharPopUp = this.onFecharPopUp.bind(this);
        this.extratoMensalService = new ExtratoMensalNFService();
        this.salvarProtocolo = this.salvarProtocolo.bind(this);
        this.onGerarLote = this.onGerarLote.bind(this);
        this.encaminharTela = this.encaminharTela.bind(this);
        this.show_popUpMercadoria = this.show_popUpMercadoria.bind(this);
        this.onHideMercadorias = this.onHideMercadorias.bind(this);
        this.onHide3 = this.onHide3.bind(this);
    }

    componentDidMount() {
        this.onLogin();
        this.tipoEmissaoService.getTipoEmissao(this).then(tipoEmissao => this.setState({ tipoEmissao: tipoEmissao }));
    }

    onHideMercadorias() {
        this.setState({ popUpMercadoria: false });
    }

    onHide3() {
        this.setState({ popUpMercadoria: false });
    }

    onPesquisaServicos() {
        
      let cert = this.state.assistencia !==  '' ? this.state.assistencia : 0 ;
      let assist = this.state.certificado !==  '' ?  this.state.certificado : 0;

      if (this.validaCampos()) {
        this.extratoMensalService.getPesquisaServico(this.state.cnpj, this.state.dataInicioFmt, this.state.dataFimFmt, this.state.status,  cert, assist)
        .then(result =>  this.getDadosEmpresa(result))
          .catch((err) => {
            err.response !== null ? this.growl.show({ severity: 'warn', summary: 'Atenção', detail: err.response.data.body })
            : alert("Problemas ao se conectar com o servidor");
         })
      } 
    }

    getDadosEmpresa(servicos) {
        this.setState({ servicosPendentes: servicos.servicosPendentesList });
        this.setState({ dadosEmpresa: servicos });
    }
    

    validaCampos(){
        var numbers = [];

        // if (this.state.cnpj == null) {
        //     numbers.push({ severity: 'warn', summary: 'Atenção', detail: 'O campo  CNPJ é de preenchimento obrigatório' });
        //     this.setState({ cnpjRequired: true });
        // } else {
        //     this.setState({ cnpjRequired: false });
        // }

        if (this.state.dataInicio == null) {
            numbers.push({ severity: 'warn', summary: 'Atenção', detail: 'O campo Data Início é de preenchimento obrigatório' });
            this.setState({ dataInicioRequired: true });
        } else {
            this.setState({ dataInicioRequired: false });
        }

        if (this.state.dataFim == null) {
            numbers.push({ severity: 'warn', summary: 'Atenção', detail: 'O campo  Data Fim é de preenchimento obrigatório' });
            this.setState({ dataFimRequired: true });
        } else {
            this.setState({ dataFimRequired: false });
        }

        if (numbers.length > 0) {
            this.growl.show(numbers);
            return false
        } else {
            return true;
        }
    }

    onLimparCampos() {
        this.setState({ dataInicioFmt: "" });
        this.setState({ dataInicio: null });
        this.setState({ dataFimFmt: "" });
        this.setState({ dataFim: null });

        this.setState({ servicosPendentes: "" });
        // this.setState({ cnpj: "" });
        this.setState({ dadosEmpresa: "" });
        this.setState({ certificado: "" });
    }

    onLogin(){
        const token = localStorage.getItem('user_token');
        const idPrestadorLogado = localStorage.getItem('idPrestadorLogado')

        if (token === null || token.length < 10) {
            window.location = '/';
        }

        if (idPrestadorLogado === null) {
            window.location = '/';
        }
    }

    onChangedataInicio = (date) => {
        this.setState({ dataInicio: date })
        try {
            let dia = (date.getDate() > 9) ? date.getDate() : ('0' + date.getDate());
            let mes = (date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
            let ano = date.getFullYear();

            this.setState({ dataInicioFmt: dia + "" + mes + "" + ano });
        } catch (error) {

        }
    }

    onChangedataFim = (date) => {
        this.setState({ dataFim: date })
        try {
            let dia = (date.getDate() > 9) ? date.getDate() : ('0' + date.getDate());
            let mes = (date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
            let ano = date.getFullYear();

            this.setState({ dataFimFmt: dia + "" + mes + "" + ano });
        } catch (error) {

        }
    }

    actionTemplateValido(rowData, column) {
        const itemValido = rowData.valido
        if (itemValido) {
            return <i className="pi pi-check-circle green"></i>
        } else {
            return <i className="pi pi-times-circle red"></i>
        }
    }

    salvarProtocolo() {
        let vlr = 0;
        let apenasValidos2 = [];


        if (this.state.itensSelecionados.length > 0) {

            this.state.itensSelecionados.forEach(function (item, index) {
                if (item.valido) {
                    vlr = vlr + item.valor;
                    apenasValidos2.push(item);
                }
            });
            
            this.setState({ valorTotal: vlr.toFixed(2)  });
            this.setState({ txtNumeroProtocolo: "Nota fiscal enviada com sucesso para análise. <br>Protocolo de envio: " });
            this.setState({ apenasValidos: apenasValidos2 });
            this.setState({ visible3: true });
        } else {
            this.growl.show({ severity: 'warn', summary: 'Atenção', detail: "Escolha pelo menos 1 serviço" });
        }
    }

    onGerarLote() {

        this.extratoMensalService.postGravarProtocolo(this.state.cnpj, this.state.itensSelecionados)
            .then(idLote => this.encaminharTela( idLote.value) )
            .catch((err) => {
                if (err.response !== null) {
                    this.growl.show({ severity: 'warn', summary: 'Atenção', detail: err.response.data.body });
                } else {
                    alert("Problemas ao se conectar com o servidor");
                }
            })
    }

    encaminharTela(idLote){
        window.location = '/nota/' + this.state.cnpj + '/' + idLote;
    }

    export() {
        this.dt.exportCSV();
    }

    onNovoLote() {
        this.onLimparCampos();
    }

    onFecharPopUp() {
        this.setState({ visible3: false });
    }

    show_popUpMercadoria() {
       this.setState({ dataFim: null  });
       this.setState({ popUpMercadoria: true });
    }


    // usado para esconder o header do popup de Mensageem
    renderHeader() {
        return (<span className="ql-formats"></span>);
    }

    actionTemplateBtnServico(rowData, column) {

        return <div className="content-section implementation">
             <i className="pi pi-file" tooltip="Lista de Serviços"  style={{ fontSize: '2.25em', color: '#005d85' }} 
                onClick={this.show_popUpMercadoria} > </i>
                
                <Dialog header="Móveis constantes no certificado" visible={this.state.popUpMercadoria} 
                      onHide={this.onHideMercadorias}   style={{ width: '30vw' }} maximizable>
                            <Editor headerTemplate={this.renderHeader()} style={{ height: '150px' }} value={rowData.descricaoMercadoria} />
                </Dialog>
        </div>
    }

    render() {
        
        const { nomePesquisa} = this.state.dadosEmpresa;

        const footerProtocolo = (<div>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button label="Cancelar" icon="pi pi-times" onClick={this.onFecharPopUp} />
            <Button label="Gerar Lote" icon="pi pi-save" onClick={this.onGerarLote } /> </div>
        );

        const lstStatusServico = [ { label: 'Todos', value: 'false' },{ label: 'Serviços Válidos', value: 'true' }];

        return (
            <div className="p-grid">
                <TituloPagina titulo="Extrato de Serviços" descricao="Extrato Serviços no período "></TituloPagina>
                <div className="p-col-12 p-lg-12">
                    <div className="p-grid">
                        {/* <div className="p-col-12 p-lg-2">
                            <label>CNPJ</label>
                            <InputText id="extratoServico_cnpj" className={(this.state.cnpjRequired ? "p-error" : " ")} value={this.state.cnpj} onChange={(e) => this.setState({ cnpj: e.target.value })} />
                        </div> */}

                        <div className="p-col-12 p-lg-2">
                            <label>Data Inicio</label>
                            <Calendar key="1" id="extratoServico_dataInicio"
                                minDate={this.state.minDate}
                                className={(this.state.dataInicioRequired ? "p-error" : " ")} 
                                value={this.state.dataInicio} style={{ width: '100%' }}
                                onChange={(e) => this.onChangedataInicio(e.value)} locale={traducaoCalendar.br} dateFormat="dd/mm/yy" />
                        </div>

                        <div className="p-col-12 p-lg-2">
                            <label>Data Fim</label>
                            <Calendar id="extratoServico_dtFim" 
                                key="2"
                                minDate={this.state.dataFim}
                                className={(this.state.dataFimRequired ? "p-error" : " ")} 
                                value={this.state.dataFim} 
                                style={{ width: '100%' }}
                                onChange={(e) => this.onChangedataFim(e.value)} locale={traducaoCalendar.br} dateFormat="dd/mm/yy" />
                        </div>

                        <div className="p-col-12 p-lg-2">
                            <label>Status</label>
                            <Dropdown id="extratoServico_tipoEmissao"
                                placeholder="Serviços Válidos"
                                options={lstStatusServico}
                                value={this.state.status}
                                onChange={event => this.setState({ status: event.value })}
                                autoWidth={false} />
                        </div>
                        <div className="p-col-12 p-lg-2">
                            <label>Certificado</label>
                            <InputMask mask="99.9999.999999999" value={this.state.certificado} onChange={(e) => this.setState({certificado: e.value})}></InputMask>
                        </div>
                        <div className="p-col-12 p-lg-2">
                            <label>Assistência</label>
                            <InputMask mask="9999999" id="extratoServico_cnpj" value={this.state.assistencia} onChange={(e) => this.setState({assistencia: e.target.value })}> </InputMask>
                        </div>

                    </div>

                    <div className="p-grid">
                        <div className="p-col-12 p-lg-7">
                            <span className="spanLabel" > { nomePesquisa !== null ? "Nome: " : null } <span className="spanNomePesquisa" >&nbsp; {nomePesquisa} </span>  </span>
                        </div>
                        <div className="p-col-12 p-lg-5 div-botoes">
                            <Button label="Pesquisar" onClick={this.onPesquisaServicos} icon="pi pi-search" />
                            {/* <Button label="Lote" icon="pi pi-save"  onClick={this.salvarProtocolo} /> */}
                        </div>
                    </div>
                </div>

                <div className="p-col-12">
                    <DataTable header="Serviços"
                        value={this.state.servicosPendentes}
                        selection={this.state.itensSelecionados}
                        onSelectionChange={e => this.setState({ itensSelecionados: e.value })}
                        dataKey="idItemLista"
                        paginator={true} rows={10}
                        responsive={true} >
                        {/* <Column selectionMode="multiple"                        style={{ textAlign: 'center', width: '45px' }} /> */}
                        <Column field="assistencia"     header="Assistência"    style={{ textAlign: 'center', width: '90px' }} />
                        <Column field="barra"           header="Barra"          style={{ textAlign: 'center', width: '60px'  }} />
                        <Column field="certificado"     header="Certificado"    style={{ textAlign: 'center', width: '165px'  }} />
                        <Column field="dataAbertura"    header="Data Abertura"  style={{ textAlign: 'center',  width: '140px'  }} />
                        <Column field="dataFechamento"  header="Data Fechamento" style={{ textAlign: 'center',  width: '140px'  }} />
                        <Column                         header="Válido"         body={(rowData, column) => this.actionTemplateValido(rowData, column)} style={{ textAlign: 'center', width: '70px' , margin: 0, padding: 0 }} />
                        <Column field="descricaoStatus" header="Situação"       style={{ textAlign: 'center', width: '150px' }} />
                        <Column field="valorFormatado"           header="Valor do Serviço"          style={{ textAlign: 'center', width: '150px' }} />
                        <Column field="valorMercadoriaFormatada"  header="Valor Mercadoria" style={{ textAlign: 'center', width: '150px' }} />
                        <Column field="percentualMontador"  header="Percentual" style={{ textAlign: 'center', width: '100px' }} />
                        {/* <Column body={(rowData, column) => this.actionTemplateBtnServico(rowData, column)} style={{ textAlign: 'center', width: '55px' }} /> */}

                    </DataTable>

                    <Dialog header="Deseja gerar o lote com os valores abaixo?" visible={this.state.visible3} style={{ width: '50vw', height: '40vw' }} footer={footerProtocolo} onHide={() => this.onHide3} >
                      <div id="popupLote" className="p-col-12 ">
                        <fieldset className=" resumo divLote">
                            <div className="p-grid">
                                <div className="p-col-4">
                                    <label>Nome</label>
                                    <h4>{nomePesquisa} </h4>
                                </div>
                                <div className="p-col-4">
                                    <label>CNPJ</label>
                                    <h4>{this.state.cnpj}</h4>
                                </div>
                                <div className="p-col-4">
                                    <label>Valor Total</label>
                                    <h4>R$ {this.state.valorTotal}</h4>
                                </div>
                                <div className="p-col-12 frase">
                                    Os itens inconsistentes serão apagados da lista de geração de Lote.
                                </div>
                            </div>
                        </fieldset>
                        <div className="p-grid">
                            <div className="p-col-12 ">
                                <DataTable
                                    ef={(el) => { this.dt = el; }}
                                    value={this.state.apenasValidos}
                                    dataKey="idItemLista"
                                    paginator={true}
                                    responsive={true} >
                                    <Column field="assistencia" header="Assistência" style={{ textAlign: 'center', width: '100px' }} />
                                    <Column field="barra" filter={false} header="Barra" style={{ textAlign: 'center', width: '55px' }} />
                                    <Column field="valor" filter={false} header="Valor" style={{ textAlign: 'center' }} />
                                    <Column field="dataAbertura" filter={false} header="Data Abertura" style={{ textAlign: 'center', width: '25%' }} />
                                    <Column field="dataFechamento" filter={false} header="Data Fechamento" style={{ textAlign: 'center', width: '25%' }} />
                                </DataTable>
                            </div>
                        </div>
                      </div>
                    </Dialog>
                </div>

                <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />
            </div>

        );
    }
}

