import React, { Component } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ExtratoMensalNFService } from '../../service/notaFiscal/ExtratoMensalNFService';
import { Growl } from 'primereact/growl';
import { TituloPagina } from '../TituloPagina';
import {Dialog} from 'primereact/dialog';
import { Editor } from "primereact/editor";

import './nfPrestador.css';

export class ExtratoMensalNF extends Component {

  constructor() {
    super();
      this.state = {
        layoutMode: 'static',
        listNotasFiscais: [],
        listItens: [],
        listDetalharMercadoriasNF: [],
        listAno: [],
        listMes: [],
        popUpServicosNF: false,
        popUpDetalharMercadoriasNF: false,

        anoSelecionado: null,
        mesSelecionado: null,

        mesRequired: false,
        anoRequired: false,
        totalValorNota: 0,
        totalValorPago: 0,
        height: 0
    };

    this.onGeraExtrato = this.onGeraExtrato.bind(this);
    this.ExtratoMensalNFService = new ExtratoMensalNFService();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onHideServicosNF = this.onHideServicosNF.bind(this);
    this.onHideDetalharMercadoriasNF = this.onHideDetalharMercadoriasNF.bind(this);
  }

  componentDidMount() {
    this.getComboTresUltimosAnos();
    this.getComboMes();
    this.updateWindowDimensions();
    this.onLogin();
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight - 105 });
  }

  onGeraExtrato() {
    if (this.validaCampos()) {
      this.ExtratoMensalNFService.getExtratoNFs(this.state.mesSelecionado.value, this.state.anoSelecionado.value)
        .then(listNotasFiscais1 => this.setState({ listNotasFiscais: listNotasFiscais1.body.lancamentos, totalValorNota: listNotasFiscais1.body.totalNotas, totalValorPago: listNotasFiscais1.body.totalPago }))
        .catch((err) => {
          if (err.response ===  null){
            this.growl.show({ severity: 'warn', summary: 'Atenção', detail: err.response.data.body });
          }else{
            this.growl.show({ severity: 'warn', summary: 'Atenção', detail: "Erro 500"});
          }
          this.setState({ listNotasFiscais: null });
          this.setState({ totalValorNota: 0 });
          this.setState({ totalValorPago: 0 });
        })
      }
    }

  getComboTresUltimosAnos = () => {
    let date = new Date();
    for (let ano_ = date.getFullYear(); ano_ > date.getFullYear() - 3; ano_--) {
      this.state.listAno.push({ name: ano_, value: ano_ });
    }
  }

  getComboMes = () => {
    const meses1 = [
      { name: 'Janeiro', value: '1' },
      { name: 'Fevereiro', value: '2' },
      { name: 'Março', value: '3' },
      { name: 'Abril', value: '4' },
      { name: 'Maio', value: '5' },
      { name: 'Junho', value: '6' },
      { name: 'Julho', value: '7' },
      { name: 'Agosto', value: '8' },
      { name: 'Setembro', value: '9' },
      { name: 'Outubro', value: '10' },
      { name: 'Novembro', value: '11' },
      { name: 'Dezembro', value: '12' }
    ];
        this.setState({ listMes: meses1 });
    }

    validaCampos = () => {
        var numbers = [];

        if (this.state.mesSelecionado == null || this.state.mesSelecionado.value == null) {
            numbers.push({ severity: 'warn', summary: 'Atenção', detail: 'O campo MÊS é de preenchimento obrigatório' });
            this.setState({ mesRequired: true });
        } else {
            this.setState({ mesRequired: false });
        }

        if (this.state.anoSelecionado == null || this.state.anoSelecionado.value == null) {
            numbers.push({ severity: 'warn', summary: 'Atenção', detail: 'O campo ANO é de preenchimento obrigatório' });
            this.setState({ anoRequired: true });
        } else {
            this.setState({ mesRequired: false });
        }

        if (numbers.length > 0) {
            this.growl.show(numbers);
            return false
        } else {
            this.setState({ listNotasFiscais: null });
            return true;
        }
    }

    onLimparCampos = () => {
        this.setState({ anoSelecionado: "" });
        this.setState({ mesSelecionado: "" });
        this.setState({ listNotasFiscais: null });
        this.setState({ totalValorNota: 0 });
        this.setState({ totalValorPago: 0 });
    }

    onLogin = () => {
        const token = localStorage.getItem('user_token');
        const idPrestadorLogado = localStorage.getItem('idPrestadorLogado');

        if (token === null || token.length < 30) {
            window.location = '/';
        }

        if (idPrestadorLogado === null) {
            window.location = '/';
        }
    }

    getItensDaNota = (row) =>{
        this.ExtratoMensalNFService.getItensNota(row)
        .then(listNotasFiscais1 => this.setState({ listItens: listNotasFiscais1 }))
        .catch((err) => {
            alert(err);
            console.log(err);
        })
    }
  
    // usado para esconder o header do popup de Mensageem
    renderHeader() {
        return (<span className="ql-formats"></span>);
    }


    actionTemplateBtnServico(rowData, column) {

        return <div className="content-section implementation">
            <i className="pi pi-file" tooltip="Lista de Serviços"  style={{ fontSize: '2.25em', color: '#005d85' }} 
                onClick={(e) => { this.setState({popUpServicosNF: true}); this.getItensDaNota(rowData); }} > </i>

            <Dialog header="Serviços Contantes na NF" visible={this.state.popUpServicosNF} style={{width: '70vw'}} onHide={this.onHideServicosNF} >

                <div className="p-grid">
                    <div className="p-col-12 "  style={{overflow: 'auto', height: '350px'}}  >
                        <DataTable size="small"  maximized={true} value={this.state.listItens} dataKey="idAssistencia" paginator={true} rows={6} responsive={true} >
                            <Column field="idAssistencia"   header="Assistencia"     style={{ textAlign: 'center', width: '90px' }} />
                            <Column field="barra"           header="Barra"           style={{ textAlign: 'center', width: '55px' }}  />
                            <Column field="certificado"     header="Certificado"     style={{ textAlign: 'center', width: '130px' }} />
                            <Column field="dataFechamento"  header="Data Fechamento" style={{ textAlign: 'center', width: '100px' }} />
                            <Column field="dataFechamento"  header="Data Fechamento" style={{ textAlign: 'center', width: '100px' }} />
                            <Column field="valorFormatado"           header="Valor do Serviço"           style={{ textAlign: 'center', width: '150px'  }} />
                            <Column field="valorMercadoriaFormatada"  header="Valor Mercadoria" style={{ textAlign: 'center', width: '150px' }} />
                            <Column field="percentualMontador"  header="Percentual" style={{ textAlign: 'center', width: '100px' }} />
                            <Column body={(rowData, column) => this.actionTemplateBtnDetalharMercadoriasNF(rowData, column)} style={{ textAlign: 'center', width: '50px' }} />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </div>
    }

    actionTemplateBtnDetalharMercadoriasNF(rowData, column) {

        return <div className="content-section implementation">
            <i className="pi pi-info-circle" tooltip='Lista de Detalhes'  style={{ fontSize: '2.25em', color: '#005d85' }} 
                onClick={(e) => { this.setState({popUpDetalharMercadoriasNF: true}); this.getDetalharMercadorias(rowData); }} > </i>

            <Dialog modal={false} header="Detalhe das Mercadoria da NF" visible={this.state.popUpDetalharMercadoriasNF} style={{width: '70vw'}} onHide={this.onHideDetalharMercadoriasNF} >

                <div className="p-grid">
                    <div className="p-col-12 "  style={{overflow: 'auto', height: '370px'}}  >
                        <DataTable value={this.state.listDetalharMercadoriasNF} dataKey="descricaoMercadorias" paginator={true} rows={10} responsive={true} >
                            <Column field="descricaoMercadorias"   header="Descrição"     style={{ textAlign: 'center', width: '90px' }} />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </div>
    }

    getDetalharMercadorias = (row) =>{ 
        this.ExtratoMensalNFService.getDetalharMercadorias(row)
        .then(_listDetalharMercadoriasNF => this.setState({ listDetalharMercadoriasNF: _listDetalharMercadoriasNF }))
        .catch((err) => {
            alert(err);
            console.log(err);
        })
    }

    formataNumber(valor) {
        var parts = valor.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    
        return "R$ " + parts.join(",");
    }

    onHideServicosNF() {
        this.setState({ popUpServicosNF: false });
    }

    onHideDetalharMercadoriasNF() {
        this.setState({ popUpDetalharMercadoriasNF: false });
    }


    render() {


        return (
            <div className="p-grid">
                <TituloPagina titulo="Extrato Mensal de Nota Fiscal" descricao="Extrato Mensal de Notas Fiscal emitidas no período"></TituloPagina>

                <div className="p-col-12 p-lg-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-3">
                            <label>Mês</label>
                            <Dropdown value={this.state.mesSelecionado}
                                options={this.state.listMes}
                                className={(this.state.mesRequired ? "p-error" : " ")}
                                onChange={event => this.setState({ mesSelecionado: event.value, mesRequired: false })}
                                placeholder="Selecione o mês" optionLabel="name" />
                        </div>
                        <div className="p-col-12 p-lg-2">
                            <label>Ano</label>
                            <Dropdown value={this.state.anoSelecionado}
                                className={(this.state.anoRequired ? "p-error" : " ")}
                                options={this.state.listAno}
                                onChange={event => this.setState({ anoSelecionado: event.value, anoRequired: false })}
                                placeholder="Selecione o Ano" optionLabel="name" />
                        </div>
                        <div className="p-col-12 p-lg-3">
                          
                        </div>
                        <div className="p-col-12 p-lg-4 div-botoes">
                            <Button label="Procurar" icon="pi pi-search" onClick={this.onGeraExtrato} />
                        </div>
                    </div>
                </div>


            <div className="p-grid">
                <div className="p-col-12">
                    <DataTable 
                        value={this.state.listNotasFiscais}
                        paginatorPosition="bottom"
                        selectionMode="single"
                        dataKey="idNotaFiscal"
                        paginator={true} rows={8}
                        responsive={true} >
                        <Column field="dataEmissao" header="Data Emissão" style={{ textAlign: 'center', width: '90px' }} />
                        <Column field="idLote" header="Transação de Pagto" style={{ textAlign: 'center', width: '90px' }} />
                        <Column field="dataPagamento" header="Data Prev. Pagto" style={{ textAlign: 'center', width: '90px' }} />
                        <Column field={"numeroNF"} header="Número/ Série" style={{ textAlign: 'center', width: '90px' }} />
                        <Column field={"descricao"} header="Status" style={{ textAlign: 'center', width: '90px' }} />
                        <Column field={"dataRecebimento"} header="Data Recebimento" style={{ textAlign: 'center', width: '120px' }} />
                        <Column field={"pagamentoAntecipado"} header="Antecipado" style={{ textAlign: 'center', width: '120px'  }} />
                        <Column field={"valorBrutoFormatado"} header="Valor Nota" style={{ textAlign: 'center', width: '120px' }} footer={"Total: " + this.formataNumber(this.state.totalValorNota)} />
                        <Column body={(rowData, column) => this.actionTemplateBtnServico(rowData, column)} style={{ textAlign: 'center', width: '50px' }} />

                    </DataTable>
                </div>
            </div>
        <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />
        </div >

        );
    }
}