let configint = "" ;

const prod = {
  url: {
    API_URL: "https://nf.juvo.com.br/juvo-service"
  }
} ;

const hml = {
  url: {
    API_URL: "https://hml-nf.juvo.com.br/juvo-service"
  }
};

const dev = {
  url: {
    API_URL: "https://hml-nf.juvo.com.br/juvo-service"
  }
};
//    API_URL: "http://10.5.4.148:8070/juvo-service"

if (process.env.NODE_ENV === "development") {
  configint = dev;
} else if (process.env.NODE_ENV === "homolog") {
  configint = hml;
} else {
  configint = prod;
}

export const config = configint;
