import React, { Component } from 'react';

export class TituloPagina extends Component {

    render() {
        return (
            <div className="p-col-12">
                <h1>{this.props.titulo}</h1>
                <h2>{this.props.descricao}</h2>
            </div>
        );
    }
}