let configint = "";

const prod = {
    url: {
        API_URL: 'https://api.juvo.com.br/juvoPrestador/autenticacao/autenticar/login'
    }
};

const hml = {
    url: {
        API_URL: 'https://api-hml.juvo.com.br/juvoPrestador/autenticacao/autenticar/login'
    }
};

const dev = {
    url: {
        API_URL: 'https://api-hml.juvo.com.br/juvoPrestador/autenticacao/autenticar/login'
    }
};


if (process.env.NODE_ENV === 'development') {
    configint = dev;
} else if (process.env.NODE_ENV === 'homolog') {
    configint = hml;
} else {
    configint = prod;
}

export const config = configint;