import React, { Component } from 'react'
import { config } from '../../constants/Environment'
import { TituloPagina } from '../TituloPagina' 
import pdf from './contrato.pdf'

export class Contrato extends Component {
  constructor () {
    super()

    this.state = {
      layoutMode: 'static',
      api_url: config.url.API_URL,
      msgSuccess: { severity: 'success', summary: 'Sucesso', detail: 'Solicitação realizada com sucesso' }
    }
  }

  componentDidMount () {

  }

  render () {
    return (
      <div className='p-grid'>
        <TituloPagina titulo='Contrato' descricao='Central de Downloads' />
        <div className='p-col-12 linkDownload'><br/><br/>
          <a href={pdf}  target="_blank"> <i class="pi pi-download"></i> Clique aqui para visualização do contrato</a>
        </div>
      </div>
    )
  }
}
