import axios from 'axios';
import { config } from '../constants/Environment'

var url = config.url.API_URL;

export const api = axios.create({
    baseURL: url,
    headers:{
        token: localStorage.getItem('user_token'),
		loginPrestador: localStorage.getItem('login')		
    }
});

api.interceptors.response.use((response) => {
	if(response.status === 401) {
		home();
	} else {
		return response;	
	}    
}, (error) => {		
	
	if(error.response.status === 401) {
		home();
	}
	
    return Promise.reject(error.message);
});

function home(){
	localStorage.setItem('user_token', null);
    localStorage.setItem('user_name', "");
    localStorage.setItem('idPrestadorLogado', null);        
    window.location = '/';
}
// http://localhost:8080/juvo-service/
// https://api-hml.juvo.com.br/juvoPrestador/autenticacao/autenticar/login
// 10.5.4.148 //Alysson //
// hml-nf.juvo.com.br   //