let configint = "";

const prod = {
    url: {
        BEARER: 'Bearer 92a773b4-8905-3441-9702-1df8a96e2cfe'
    }
};

const hml = {
    url: {
        BEARER: 'Bearer ca99740a-aff9-3747-8d47-a803e382bba0'
    }
};

const dev = {
    url: {
        BEARER: 'Bearer ca99740a-aff9-3747-8d47-a803e382bba0'
    }
};


if (process.env.NODE_ENV === 'development') {
    configint = dev;
} else if (process.env.NODE_ENV === 'homolog') {
    configint = hml;
} else {
    configint = prod;
}

export const bearer = configint;